import React from 'react'

const BtnMotivaciones = () => {
    return (
        <button
            className='w-[96%] mobile:w-[269px] h-[56px] sm:w-[276px] sm:h-[52px]
            hoverbt'>
            <p>Consulta tu pedido</p>
        </button>
    )
}

export default BtnMotivaciones
