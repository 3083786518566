import Splash from "./components/animations/Splash";
import BtnWsp from "./components/buttons/BtnWsp";
import Home from "./pages/Home";
import Footer from "./shared/Footer";
import NavBar from "./shared/NavBar";
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react";

function App() {
   const [splash, setSplash] = useState(true);
   useEffect(() => {
      setTimeout(() => {
         setSplash(false);
      }, 1000);
   }, []);
   return (
      <AnimatePresence>
         {
            splash ?
               (<Splash key="splash" />)
               :
               (
                  <motion.div
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ duration: 1.5 }}
                     className="relative"
                  >
                     <NavBar />
                     <Home />
                     <Footer />
                     <BtnWsp/>
                  </motion.div>
               )
         }
      </AnimatePresence>
   );
}

export default App;
