import React from 'react'

const Footer = () => {
    return (
        <footer
            className='bg-[url("/public/footer/bg-footer.jpg")] bg-cover bg-center relative
            before:bg-[#000000] before:opacity-70 before:w-full before:h-full before:absolute !z-10'>
            <div
                className='grid justify-center text-white text-center !z-20
                pt-[50px] pb-[32px] sm:py-[56px] lg:py-[72px] px-[4%]'>
                <h2 className='z-30'>¿Te gustaría colaborar con nosotros?</h2>
                <p
                    className='font-[Mulish] sm:font-semibold z-30
                    text-[16px] md:text-[20px] lg:text-[24px]
                    pt-[32px] pb-[24px] md:pt-[40px] md:pb-[32px]'>
                    Envíanos un mensaje y con gusto conversamos
                </p>
                <div className='grid gap-[16px] sm:gap-[32px] z-30'>
                    <div
                        className='flex justify-center'>
                        <a target='_blank' rel="noreferrer"
                            href='https://www.instagram.com/nutrip.peru/'>
                            <img alt='instagram'
                                src='/svg/instagram.svg'
                                className='cursor-pointer hover:scale-125' />
                        </a>
                    </div>
                    <p
                        className='font-[Sora] font-bold text-[24px] text-[#FF9A2E] 
                        order-2 sm:order-1'>
                        nutrip.peru@gmail.com
                    </p>
                    <p
                        className='font-[Mulish] sm:font-semibold order-3
                        text-[16px] md:text-[20px] lg:text-[24px]'>
                        +51 993 717 722
                    </p>
                </div>
                <img alt='Nutrip'
                    src='/svg/logoFooter.svg'
                    className='mx-auto w-[92%] mobile:w-[248px] sm:w-[236px]
                    pt-[48px] pb-[38px] md:pt-[64px] md:pb-[72px] z-30'>
                </img>
                <p className='font-tahoma text-[16px] sm:text-[14px] z-30'>
                    Todos los derechos reservados © NUTRIP | Design by
                    <span className='font-bold cursor-pointer'>
                        <a target='_blank' rel='noreferrer'
                            href='https://ciclos.studio/'
                            className=' hover:text-[#FFD600]'>
                            {" "} CICLOS STUDIO
                        </a>
                    </span>
                </p>
            </div>
        </footer>
    )
}

export default Footer