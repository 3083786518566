import React, { useState } from 'react'
import BtnUnete from '../components/buttons/BtnUnete'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-scroll'
import { motion } from "framer-motion"

const NavBar = () => {
   const [menu, setMenu] = useState(false)
   const isLg = useMediaQuery({ query: '(min-width: 890px)' })
   return (
      <header
         className='flex items-center justify-center relative
         w-full h-[80px] sm:h-[88px]'>
         <nav
            className='containerNav flex items-center justify-between'>
            <img alt='Nutrip'
               src='/svg/logoNav.svg'
               className='w-[93px] sm:w-[95px]'>
            </img>
            {isLg ?
               <div
                  className='flex items-center gap-[32px] xl:gap-[40px]'>
                  <Link
                     to="Nuestra-historia"
                     spy={true}
                     offset={60}
                     smooth={true}
                     duration={500}>
                     <p className='pNavLine'>Nosotros</p>
                  </Link>
                  <Link
                     to="Motivaciones"
                     spy={true}
                     offset={30}
                     smooth={true}
                     duration={500}>
                     <p className='pNavLine'>Nuestra motivación</p>
                  </Link>
                  <Link
                     to="Testimonios"
                     spy={true}
                     offset={40}
                     smooth={true}
                     duration={500}>
                     <p className='pNavLine'>Testimonios</p>
                  </Link>
                  <BtnUnete />
               </div>
               :
               <img alt='menú'
                  src='/svg/burger.svg'
                  className='sm:h-[40px]'
                  onClick={() => setMenu(!menu)} />
            }
         </nav>
         {menu && !isLg &&
            <motion.div
               initial={{ opacity: 0, x: 100 }}
               animate={{ opacity: 1, x: 0 }}
               exit={{ opacity: 0, x: 100 }}
               transition={{ duration: 1 }}
               className='absolute right-0 top-0 !z-20
               w-full mobile:w-[360px] bg-[#011002]
               pt-[131px] pb-[98px]'>
               <div
                  className='grid justify-center gap-[40px]'>
                  <Link
                     to="Nuestros-inicios"
                     spy={true}
                     offset={-150}
                     smooth={true}
                     duration={500}
                     onClick={() => setMenu(!menu)}>
                     <p className='pNav'>Nosotros</p>
                  </Link>
                  <Link
                     to="Motivaciones"
                     spy={true}
                     offset={-54}
                     smooth={true}
                     duration={500}
                     onClick={() => setMenu(!menu)}>
                     <p className='pNav'>Nuestra motivación</p>
                  </Link>
                  <Link
                     to="Testimonios"
                     spy={true}
                     offset={-54}
                     smooth={true}
                     duration={500}
                     onClick={() => setMenu(!menu)}>
                     <p className='pNav'>Testimonios</p>
                  </Link>
                  <div className='flex justify-center'>
                     <BtnUnete />
                  </div>
               </div>
               <img alt='menú'
                  onClick={() => setMenu(!menu)}
                  src='/svg/close.svg'
                  className='sm:h-[32px]
                  absolute top-[30px] right-[30px]' />
            </motion.div>
         }
      </header>
   )
}

export default NavBar