const Splash = () => {
    return (
        <div
            className='flex justify-center items-center h-[100vh] bg-white z-20'>
            <img alt="Nutrip"
                src="/svg/logoNav.svg"/>
        </div>
    )
}

export default Splash
