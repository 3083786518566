import React from 'react'

const BtnLee = () => {
    return (
        <button
            className='w-[96%] mobile:w-[300px] h-[56px] sm:w-[376px] sm:h-[52px]
            hoverbt'>
            <p>Lee nuestras historias</p>
        </button>
    )
}

export default BtnLee
