import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"
import { Pagination } from "swiper";
import CardComentarios from "../cards/CardComentarios";

export default function ComentariosSwiper() {
   return (
      <>
         <Swiper
            slidesPerView={"auto"}
            spaceBetween={16}
            modules={[Pagination]}
            breakpoints={{
               620: {
                  maxWidth: "800px",
                  slidesPerView: 2.5,
                  slidesPerGroup: 1,
                  spaceBetween: 24,
               },
               450: {
                  maxWidth: "800px",
                  slidesPerView: 1.8,
                  slidesPerGroup: 1,
                  spaceBetween: 24,
               },
               360: {
                  maxWidth: "328px",
                  slidesPerView: 1.4,
                  slidesPerGroup: 1,
                  spaceBetween: 16,
               },
               260: {
                  maxWidth: "239px",
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                  spaceBetween: 16,
               },
            }}
            className="mySwiper"
         >
            <SwiperSlide>
               <CardComentarios name="Soledad, 55 años" lugar='Chaclacayo'
                  text="Me gustan las paltas nutrip porque al ser orgánicas, cuido no solo mi alimentación si no, el medio ambiente."
                  img="/home/comentarios/martha-comentario.png" />
            </SwiperSlide>
            <SwiperSlide>
               <CardComentarios name="Magally, 33 años" lugar='Ate, Lima'
                  text="Están buenazas, además de que es producida de manera natural, complemento con omega 3."
                  img="/home/comentarios/magally-comentario.png" />
            </SwiperSlide>
            <SwiperSlide>
               <CardComentarios name="Manuel, 40 años" lugar='Chincha'
                  text="Las paltas nutrip son muy cremositas, me asombró el sabor de la Hass. Recomiendo que las prueben."
                  img="/home/comentarios/manuel-comentario.png" />
            </SwiperSlide>
         </Swiper>
      </>
   );
}
