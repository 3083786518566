import React from 'react'

const BtnWsp = () => {
    return (
        <a href='https://api.whatsapp.com/send?phone=+51993717722&text=%C2%A1Hola!%20Quisiera%20saber%20m%C3%A0s%20sobre%20Nutrip%20%F0%9F%A5%91%F0%9F%98%8A'
            target='_blank' rel="noreferrer"
            className='hover:scale-110 hover:w-[88px]
            fixed right-0 top-[82%] z-10 w-[72px] '>
            <div className="bg-[#89C241] sm:bg-[#73C011]  cursor-pointer
            w-full h-[60px] flex items-center justify-center rounded-l-[40px]">
                <img alt='WhatsApp'
                    src='/svg/whatsapp.svg'>
                </img>
            </div>
        </a >
    )
}

export default BtnWsp
