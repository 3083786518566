import React from 'react'
import CardMotivaciones from '../components/cards/CardMotivaciones'
import BtnMotivaciones from '../components/buttons/BtnMotivaciones'
import CardComentarios from '../components/cards/CardComentarios'
import { useMediaQuery } from 'react-responsive'
import ComentariosSwiper from '../components/swipers/ComentariosSwiper'
import BtnLee from '../components/buttons/BtnLee'
import BtnConsulta from '../components/buttons/BtnConsulta'
import BtnConsulta2 from '../components/buttons/BtnConsulta2'

const Home = () => {
    const isMd = useMediaQuery({ query: '(min-width: 820px)' })
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    return (
        <div className='overflow-hidden relative'>
            <section id='Hero'>
                <div
                    className="containerW-1180 grid items-center relative 
                    isXl:grid-cols-[577px_1fr] gap-[23px]
                    pt-[16px] pb-[72px] sm:pb-[100px] lg:py-0">
                    <div
                        className='isXl:py-[100px] xl:py-[174px] !z-10'>
                        <h1>Valoramos la agricultura orgánica y sus historias</h1>
                        <p className='font-[Mulish] sm:font-semibold text-center max-w-[560px] mx-auto
                            text-[16px] md:text-[20px] lg:text-[24px] isXl:text-left isXl:w-full
                            py-[24px] md:pt-[20px] md:pb-[32px]'>
                            Vive la experiencia saludable y orgánica de la palta arequipeña,
                            y te obsequiamos 2 paltitas por tu primera compra.
                        </p>
                        <div
                            className='flex justify-center isXl:justify-start '>
                            {isMd ?
                                <BtnConsulta />
                                :
                                <BtnConsulta2 />
                            }
                        </div>
                    </div>
                    <img alt='Hero'
                        src='/home/hero.png'
                        className='w-full max-w-[720px] mx-auto !z-10'>
                    </img>
                    {isMd &&
                        <img alt=''
                            src='/svg/circleCyan.svg'
                            className='absolute !z-0
                            top-[8%] left-[-10%]
                            xl:top-[13%] xl:left-[-270px]'>
                        </img>
                    }
                    {isMd &&
                        <img alt=''
                            src='/svg/circleRed.svg'
                            className='absolute !z-0
                        top-[18%] right-[-8%]
                        xl:top-[36%] xl:right-[-153px]'>
                        </img>
                    }
                </div>
            </section>

            <section id='Motivaciones'
                className='bg-[#C1D7C3] relative'>
                <div
                    className='containerW-1180 
                    pt-[80px] pb-[40px]
                    sm:pt-[144px] sm:pb-[72px]'>
                    <h3>¿Qué nos motiva cada día?</h3>
                    <div
                        className='grid gap-[46px] py-[32px]
                        md:grid-cols-2 md:gap-[64px] sm:pt-[40px] sm:pb-[48px]
                        xl:grid-cols-3 xl:gap-[96px]'>
                        <CardMotivaciones
                            title="Alimentación saludable"
                            text="Te ayudamos a complementar tu nutrición balanceada"
                            img="home/motivaciones/alimentacion.jpg" />
                        <CardMotivaciones
                            title="Impacto medioambiental"
                            text="Resilencia en el sistema de producción de alimentos orgánicos"
                            img="home/motivaciones/impacto.jpg" />
                        <CardMotivaciones
                            title="Impulso a comunidades rurales"
                            text="Revalorizamos el trabajo de agricultores"
                            img="home/motivaciones/impulso.jpg" />
                    </div>
                    <div className='flex justify-center'>
                        <BtnMotivaciones />
                    </div>
                </div>
                <img
                    alt='Nutrip'
                    src='/svg/separationIcon.svg'
                    className='absolute 
                    translate-x-[-50%] left-[50%]
                    w-[85px] sm:w-[132px] lg:w-[189px]
                    top-[-37px] sm:top-[-62px] lg:top-[-73px]'
                />
            </section>

            <section id='Nuestra-historia'>
                <div
                    className="py-[40px] containerW-1134 grid gap-[32px]
                    lg:gap-[52px]  lg:pb-[77px] lg:pt-[77px] 
                    isXl:pt-[107px] isXl:grid-cols-[1fr_500px] 
                    xl:gap-[88px]">
                    <img alt="Nuestra-historia"
                        src='home/historia/nuestra-historia.jpg'
                        className='rounded-[20px]
                        object-cover w-full h-[328px] 
                        md:h-[400px] lg:h-[480px] isXl:h-full xl:h-[760px]'/>
                    <div>
                        <h3 className='isXl:text-left'>Nuestra historia</h3>
                        <div
                            className='grid gap-[24px] xl:gap-[32px]
                            font-[Mulish] text-center isXl:text-left
                            leading-[22.4px] lg:leading-[28px] xl:leading-[36px]
                            py-[32px] md:pt-[18px] md:pb-[36px] xl:pb-[52px]
                            text-[16px] md:text-[17px] lg:text-[18px]'>
                            <p>
                                Los hermanos Hipo y Pancho Cáceres, empezaron su aventura de producción de paltas por el 2019,
                                en su tierra natal, Tomepampa Arequipa y siendo ellos muy autodidactas y emprendedores, decidieron
                                aprender cómo lograr una producción más orgánica, viendo videos de YouTube, con lápiz y cuaderno
                                en mano tomando buenas prácticas de otros países y consultando a otros amig@s productores.
                            </p>
                            <p>
                                Desde entonces, cada año, esperan con ansias la nueva cosecha, a base de esfuerzo y cariño
                                para lograr una producción que le devuelva el valor a la biodiversidad de su tierra.
                                Te invitamos a descubrir más de sus aventuras aquí.
                            </p>
                        </div>
                        <div className='flex justify-center isXl:justify-start'>
                            <BtnLee />
                        </div>
                    </div>
                </div>
            </section>

            <section id='Testimonios'
                className='bg-[#C1D7C3]'>
                <div className='containerW-1024 py-[40px] sm:py-[72px] relative'>
                    <h3>Ellos ya probaron nuestros productos</h3>
                    {isMd ?
                        <div className='flex justify-between pt-[40px]'>
                            <CardComentarios name="Soledad, 55 años" lugar='Chaclacayo'
                                text="Me gustan las paltas nutrip porque al ser orgánicas, cuido no solo mi alimentación si no, el medio ambiente."
                                img="/home/comentarios/martha-comentario.png" />
                            <CardComentarios name="Magally, 33 años" lugar='Ate, Lima'
                                text="Están buenazas, además de que es producida de manera natural, complemento con omega 3."
                                img="/home/comentarios/magally-comentario.png" />
                            <CardComentarios name="Manuel, 40 años" lugar='Chincha'
                                text="Las paltas nutrip son muy cremositas, me asombró el sabor de la Hass. Recomiendo que las prueben."
                                img="/home/comentarios/manuel-comentario.png" />
                        </div>
                        :
                        <div className='pt-[32px]'>
                            <ComentariosSwiper />
                        </div>
                    }
                    {isLg &&
                        <img alt=''
                            src='/svg/circleGreen.svg'
                            className='absolute
                            top-[44%] left-[-22%]
                            xl:top-[43%] xl:left-[-350px]'>
                        </img>
                    }
                    {isLg &&
                        <img alt=''
                            src='/svg/circleOrange.svg'
                            className='absolute !z-0
                        top-[18%] right-[-20%]
                        xl:top-[24%] xl:right-[-379px]'>
                        </img>
                    }
                </div>
            </section>
        </div>
    )
}

export default Home
