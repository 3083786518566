import React from 'react'

const CardComentarios = (props) => {
  return (
    <div
        className='bg-white
        w-[220px] rounded-t-full pt-[32px]'>
        <img alt={props.name}
        src={props.img}
        className='w-auto rounded-full
        mx-[30px] mb-[23px]'/>
        <div 
            className='w-full text-center 
            grid gap-[8px] py-[16px] px-[11.5px]'>
            <p className='font-[Mulish] text-[16px]'>
                {props.text}
            </p>
            <p className='font-[Sora] font-bold text-[16px] text-[#F64B25]'>
                {props.name}
                <br/>
                <span 
                  className='text-black uppercase 
                  font-[Mulish] font-normal'>
                {props.lugar}
                </span>
            </p>
        </div>
    </div>
  )
}

export default CardComentarios
