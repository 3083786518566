import React from 'react'

const BtnUnete = () => {
    return (
        <button
            className='rounded-[25px] bg-transparent border-[1px]
            border-white isLg:border-black wrapper relative
            w-[92%] mobile:w-[276px] h-[52px]'>
            <p
                className='font-[Inter] font-bold text-white isLg:text-black
                text-[20px]'>
                Únete a nosotros
            </p>
        </button>
    )
}

export default BtnUnete
