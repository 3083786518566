import React from 'react'

const CardMotivaciones = (props) => {
  return (
    <div 
        className='relative mx-auto w-full 
        mobile:w-[328px] xl:w-full
        h-[386px] sm:h-[395px]'>
      <img 
        alt={props.title}
        src={props.img}
        className='w-full rounded-[16px] sm:rounded-[20px]'
      />
      <div
        className='absolute grid items-center
        rounded-[16px] sm:rounded-[20px]
        top-[52%] mobile:top-[220px] sm:top-[230px]
        translate-x-[-50%] left-[50%]
        bg-white p-[16px] sm:px-[18px]
        w-[224px] h-[168px] sm:w-[216px] sm:h-[165px]'>
        <h4>{props.title}</h4>
        <p
            className='font-[Mulish] text-center text-[16px]'>
            {props.text}
        </p>
      </div>
    </div>
  )
}

export default CardMotivaciones
