import React from 'react'

const BtnConsulta = () => {
  return (
    <button
        className='w-[251px] sm:w-[376px] h-[56px]
        bg-[#89C241] lg:bg-[#F64B25] hoverbt'>
        <p className='text-[20px]'> Consultar pedido </p>
    </button>
  )
}

export default BtnConsulta
